<template>
  <div class='equipment'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" @change="paramsChange" size="small">
          <el-option v-for="item in unitList" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>设备编号：</span>
        <el-input @change="paramsChange" clearable v-model="argumentsList.deviceId" placeholder="请输入设备编号" size="small" />
      </div>
      <div>
        <el-button type="primary" size="small" @click="addDevice">新增设备</el-button>
        <el-button type="success" size="small" @click="createCode">生成二维码</el-button>
      </div>
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条，在线设备：<span style="color:green">{{onLineList.length}}</span>，离线设备：<span style="color:red">{{offLineList.length}}</span>
      </template>
    </el-alert>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" v-if="activeName === 'second'" size="mini" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="deviceId" label="设备编号">
      </el-table-column>
      <el-table-column prop="school" label="单位" min-width="100">
      </el-table-column>
      <el-table-column prop="location" label="位置" min-width="100">
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <span v-if="row.status === 10"><i class="online" style="background:green"></i>在线</span>
          <span v-if="row.status === 23"><i class="offline" style="background:red"></i>离线</span>
        </template>
      </el-table-column>
      <el-table-column prop="initFlow" label="结余流量">
      </el-table-column>
      <el-table-column prop="imsi" label="IMSI" min-width="110">
        <template slot-scope="{row}">
          {{row.imsi?row.imsi:'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="rssi" label="信号强度">
        <template slot-scope="{row}">
          <span :style="{color:Number(row.rssi)>20?'green':'red'}">{{row.rssi}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalFlow" label="水表读数">
      </el-table-column>
      <el-table-column prop="activiteTime" label="激活时间" min-width="120">
        <template slot-scope="{row}">
          {{row.activiteTime?$moment(row.activiteTime).format('YYYY-MM-DD HH:mm:ss'):'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="prealertthreshold" min-width="90">
        <template slot="header">
          预报警阈值
          <el-tooltip class="item" effect="dark" content="滤芯额定进水量的90%，超过预报警阈值会以短信的方式通知到学校管理员" placement="bottom-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="alertthreshold" min-width="80">
        <template slot="header">
          报警阈值
          <el-tooltip class="item" effect="dark" content="报警阈值即批件所示的滤芯额定净水量，超过报警阈值会以短信的方式通知到滤芯维护人员和学校管理员" placement="bottom-start">
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="detail" label="检测报告" min-width="120">
        <template slot-scope="{row}">
          <el-button type="text" @click="checkReport(row.id)">查看</el-button>
          <el-button type="text" @click="addReport(row)">添加</el-button>
          <el-button type="text" @click="historyReport(row.id)">查看历史</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="detail" label="操作">
        <template slot-scope="{row}">
          <!-- <el-button type="text">编辑</el-button> -->
          <el-popover placement="bottom-start" width="180" v-model="row.visible">
            <p>您确认要删除该设备吗？</p>
            <div style="text-align: right; margin: 10px 0 0 0">
              <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="deviceDelete(row),row.visible = false">确定</el-button>
            </div>
            <el-button type="text" slot="reference" style="color:red">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 查看检测报告弹框 -->
    <el-dialog title="检测报告" :visible.sync="instructionsVisible" width="20%" :lock-scroll="false">
      <el-descriptions :column="1">
        <el-descriptions-item label="最近一次检测时间">{{report.gmtcreate}}</el-descriptions-item>
        <el-descriptions-item label="检测结果">{{report.testresult}}</el-descriptions-item>
        <el-descriptions-item label="检测单位">{{report.testorg}}</el-descriptions-item>
      </el-descriptions>
      <p class="imgbox" v-if="report.reportid">
        <img :src="`https://watersupervision.terabits.cn:9091/devicemanagement/certificate/report/img/get1?access_token=${token}&reportId=${report.reportid[0]}`" alt="">
      </p>
    </el-dialog>
    <!-- 添加检测报告弹框 -->
    <el-dialog title="添加检测报告" :visible.sync="addDialog" width="30%" :lock-scroll="false">
      <p style="color:red">*注：请仔细填写，一旦提交不得修改!!</p>
      <el-form :model="addForm" :rules="rules" ref="addForm" :hide-required-asterisk="true">
        <el-form-item label="最新一次检测时间：" prop="gmtcreate">
          <el-date-picker type="date" placeholder="请选择最新一次检测时间" v-model="addForm.gmtcreate" style="width: 100%;" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="检测结果：" prop="testresult">
          <el-input v-model="addForm.testresult" placeholder="请输入检测结果">
          </el-input>
        </el-form-item>
        <el-form-item label="检测单位：" prop="testorg">
          <el-input v-model="addForm.testorg" placeholder="请输入检测单位">
          </el-input>
        </el-form-item>
        <el-form-item label="上传报告：" prop="img">
          <base-upload accept="image/jpeg,image/jpeg,image/png" listType="picture" :file.sync="addForm.img" :isShowTip="false" :fileLimit="1" uploadText="上传报告（只能上传一张图片）" @upload="upload" @remove='remove' />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="sumbitAdd">确定</el-button>
      </div>
    </el-dialog>
    <!-- 检测报告历史记录弹框 -->
    <el-dialog title="检测报告历史记录" :visible.sync="historyVisible" width="40%" :lock-scroll="false">
      <el-dialog width="30%" title="查看图片" class="imgdialog" :lock-scroll="false" :visible.sync="imgVisible" append-to-body>
        <p class="imgbox">
          <img :src="`https://watersupervision.terabits.cn:9091/devicemanagement/certificate/report/img/get1?access_token=${token}&reportId=${imgSrc}`" alt="">
        </p>
      </el-dialog>
      <p class="content">检测次数 {{historyList.length}} 次</p>
      <el-table :data="historyList" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" size="small" border>
        <el-table-column prop="testorg" label="检测单位">
        </el-table-column>
        <el-table-column prop="testresult" label="检测结果">
        </el-table-column>
        <el-table-column prop="gmtcreate" label="检测时间">
        </el-table-column>
        <el-table-column label="查看图片">
          <template slot-scope="{row}">
            <el-button type="text" @click="checkImg(row.reportid)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 二维码弹框 -->
    <qr-code ref="QRCode" />
  </div>
</template>

<script>
import { getdeviceList, getCheckReport, deleteDevice, getReportImg, addCheckReport, getHistoryReport } from '@/api'
import { mapGetters } from 'vuex'
import QrCode from './QRCode.vue'
export default {
  components: { QrCode },
  props: {
    activeName: {
      type: String
    }
  },
  data () {
    return {
      // 联系人弹窗
      contactVisible: false,
      totalList: [],
      total: 0,
      tableData: [],
      onLineList: [],
      offLineList: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      argumentsList: {
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: null,
        deviceId: ''
      },
      instructionsVisible: false,
      report: {},
      schoolId: '',
      addForm: {
        img: null,
        gmtcreate: '',
        testresult: '',
        testorg: ''
      },
      addDialog: false,
      rules: {
        img: [
          { required: true, message: '请上传报告', trigger: 'change' }
        ],
        gmtcreate: [
          { required: true, message: '请选择最新一次检测时间', trigger: 'change' }
        ],
        testresult: [
          { required: true, message: '请输入检测结果', trigger: 'blur' }
        ],
        testorg: [
          { required: true, message: '请输入检测结果', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      imgList: [],
      historyVisible: false,
      imgVisible: false,
      imgSrc: '',
      historyList: [],
      codeList: []
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['unitList', 'token', 'realname'])
  },
  methods: {
    // 获取设备列表
    async init () {
      const res = await getdeviceList(this.argumentsList)
      this.totalList = res
      this.total = res.length
      this.onLineList = res.filter(el => el.status === 10)
      this.offLineList = res.filter(el => el.status === 23)
      this.getList()
      // console.log('res :>> ', this.totalList, this.total);
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 列表筛选
    paramsChange () {
      this.queryParams.pageNum = 1
      this.init()
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 新增设备
    addDevice () {
      this.$router.push('/adddevice')
    },
    // 选择
    handleSelectionChange (val) {
      // this.multipleSelection = val;
      // console.log('val :>> ', val);
      this.codeList = val
    },
    // 生成二维码
    createCode () {
      if (this.codeList.length === 0) {
        this.$message.error('请选择需要生成二维码的设备')
      } else {
        this.$refs.QRCode.openVisible(this.codeList)
      }

    },
    // 查看检测报告
    async checkReport (schoolId) {
      this.report = await getCheckReport({ schoolId })
      this.instructionsVisible = true
    },
    // 添加检测报告
    addReport (schoolId) {
      this.schoolId = schoolId
      this.addDialog = true
    },
    // 查看检测报告历史记录
    async historyReport (deviceId) {
      const res = await getHistoryReport({ schoolId: deviceId })
      // console.log('res :>> ', res);
      this.historyList = res
      this.historyVisible = true
    },
    // 查看图片
    checkImg (val) {
      if (!val || val.length === 0) {
        this.$message.error('暂无图片')
      } else {
        this.imgSrc = val[0]
        this.imgVisible = true
      }

    },
    // 上传图片存
    upload (data) {
      this.imgList.push(data)
    },
    // 添加检测报告确定
    sumbitAdd () {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          // console.log('this.filterForm :>> ', this.filterForm, this.imgList);
          const img = await this.checkImgId()
          // console.log('img :>> ', img);
          const { gmtcreate, testresult, testorg } = this.addForm
          const params = {
            schoolId: this.schoolId.id,
            gmtcreate: this.$moment(gmtcreate).format('YYYY/MM/DD'),
            testresult,
            testorg,
            reportImgIds: img.join(','),
          }
          await addCheckReport(params)
          this.$add(
            {
              userName: this.realname,
              module: '设备管理',
              content: `${this.realname}成功添加 ${this.schoolId.school} 的检测报告`,
              type: 3,
              platform: 8,
              operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
          )
          this.$message.success('添加报告成功')
          this.addDialog = false
          this.init()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取图片id
    checkImgId () {
      const img = Promise.all(this.imgList.map(async (el) => {
        let formdata = new FormData()
        formdata.append('file', el.file, el.file.name)
        // this.updataParams.imgId = await getImgId(this.token, formdata)
        const res = await getReportImg(this.token, formdata)
        // console.log('res[0] :>> ', res[0]);
        return res[0]
      }))
      return img
    },
    // 删除图片
    remove (data) {
      this.imgList = this.imgList.filter(el => el.file.name !== data.name)
    },
    // 删除设备
    async deviceDelete (val) {
      await deleteDevice({ id: val.id })
      this.$message.success('删除设备成功')
      this.init()
      this.$add(
        {
          userName: this.realname,
          module: '设备管理',
          content: `${this.realname}删除了 ${val.school} 的设备，编号为 ${val.deviceId}`,
          type: 4,
          platform: 8,
          operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
      )
    }
  }
};
</script>

<style scoped lang="scss">
.equipment {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 16px;
      }

      border-bottom: 1px solid rgba(157, 158, 160, 0.647);
    }
    .el-dialog__body {
      // padding-top: 0;
      .item {
        margin-top: 10px;
        // font-size: 16px;
        color: #999;
        span {
          font-size: 16px;
          color: #333;
        }
      }
      .imgbox {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
        img {
          // position: absolute;
          width: 240px;
          // height: 100px;
          // transform: scale(0.5);
        }
      }
    }
  }
  .online,
  .offline {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .content {
    margin: 10px 0;
  }
}
</style>
