<template>
  <el-dialog title="二维码展示" :visible.sync="codeVisible" width="30%" top="10vh" :lock-scroll="false">
    <div class="itemCode">
      <div v-for="item in codeList" :key="item.id" ref="codebox">
        <div>
          <div id="qrcode" class="qrcode" ref="codeItem"></div>
          <p>设备编号：{{item.deviceId}}</p>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="downloadE" type="primary">下载二维码</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
export default {
  data () {
    return {
      codeVisible: false,
      qrcode: null,
      deptName: '',
      codeList: []
    };
  },
  created () {

  },
  mounted () {
  },
  methods: {
    openVisible (codeList) {
      this.codeVisible = true
      this.codeList = codeList;//设置需要的数据
      this.$nextTick(() => {
        this.bindQRCode();
      })
    },
    bindQRCode () {
      this.codeList.forEach((item, index) => {
        this.$refs.codeItem[index].innerHTML = "";
        new QRCode(this.$refs.codeItem[index], {
          text: `https://watersupervision.terabits.cn/mobile?deviceId=${item.deviceId}`, //二维码内容
          width: 200,
          height: 200,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        })
      })
    },
    downloadE () {
      if (this.codeList.length === 1) {
        html2canvas(this.$refs.codebox[0], {
          dpi: window.devicePixelRatio * 2,

          useCORS: true // 【重要】开启跨域配置
        }).then(canvas => {
          // this.$refs.addImage.append(canvas);//在下面添加canvas节点
          let link = document.createElement("a");
          link.href = canvas.toDataURL();//下载链接
          link.setAttribute("download", this.codeList[0].deviceId);
          link.style.display = "none";//a标签隐藏
          document.body.appendChild(link);
          link.click();
        })
      } else {
        const base64Array = [];
        this.codeList.forEach((item, index) => {
          let doc = this.$refs.codebox[index];
          html2canvas(doc).then(canvas => {
            base64Array.push({
              name: `${item.deviceId}.png`,
              data: canvas.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ''),
            });
            if (base64Array.length === this.codeList.length) {
              this.downloadZip(base64Array);
            }
          });
        });
      }

    },

    downloadZip (arr) {
      // 定义压缩包名
      const zipName = `设备二维码`;
      const zip = new JSZip();
      arr.forEach(item => {
        zip.file(item.name, item.data, { base64: true });
      });
      zip.generateAsync({ type: 'blob' }).then(content => {
        FileSaver.saveAs(content, `${zipName}.zip`);
      });
    }

  }
};
</script>

<style scoped lang="scss">
.itemCode {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    text-align: center;
    margin-bottom: 10px;
    p {
      border: 1px solid rgb(205, 205, 205);
      border-top: none;
      font-size: 16px;
      padding: 10px 0;
      font-weight: bold;
    }
  }
  > div:nth-of-type(2n-1) {
    margin-right: 20px;
  }
}
// ::v-deep .el-dialog__body {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   .el-button {
//     margin-top: 10px;
//     padding: 15px 90px;
//   }
// }
// .el-button--primary{
//   text-align: ri;
// }
</style>
