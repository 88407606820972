<template>
  <div class='device'>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="运行详情" name="first">
        <operation :activeName="activeName" />
      </el-tab-pane>
      <el-tab-pane label="设备详情" name="second">
        <equipment :activeName="activeName" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Equipment from './components/equipment.vue'
import Operation from './components/operation.vue'
export default {
  components: {
    Equipment,
    Operation
  },
  data () {
    return {
      activeName: 'first'
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style scoped lang="scss">
</style>
