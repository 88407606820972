<template>
  <div class='operation'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" @change="paramsChange" size="small">
          <el-option v-for="item in cascadedlocation" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
        <span>设备编号：</span>
        <el-input @change="paramsChange" clearable v-model="argumentsList.deviceId" placeholder="请输入设备编号" size="small" />
      </div>
      <!-- <el-button type="primary" size="small" @click="actionUnit('add')">新 建</el-button> -->
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条，在线设备：<span style="color:green">{{onLineList.length}}</span>，离线设备：<span style="color:red">{{offLineList.length}}</span>
      </template>
    </el-alert>
    <el-table @sort-change="sortChange" size="mini" :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" v-if="activeName === 'first'">
      <el-table-column prop="deviceId" label="设备编号">
      </el-table-column>
      <el-table-column prop="school" label="单位">
      </el-table-column>
      <el-table-column prop="location" label="位置">
      </el-table-column>
      <el-table-column prop="status">
        <template slot="header">
          状态
          <el-popover v-model="visible" placement="bottom-start" trigger="click" :visible-arrow="false" width="100" class="filterpopover">
            <el-checkbox-group v-model="filterResVal" :max="filterCondition.length">
              <el-checkbox :checked="item.checked" :label="item.label" v-for="(item,index) in filterCondition" :key="index"></el-checkbox>
            </el-checkbox-group>
            <!-- <div class="el-table-filter__bottom">
              <button @click="filterTableData">筛选</button>
              <button @click="refresh">重置</button>
            </div> -->
            <span slot="reference">
              <i class="el-icon-menu"></i>
            </span>
          </el-popover>
          <!--   <el-popover v-model="visible" :visible-arrow="false" placement="bottom" trigger="click">
            <el-checkbox-group v-model="checkList" @change="change">
              <el-checkbox label="复选框 A"></el-checkbox>
              <el-checkbox label="复选框 B"></el-checkbox>
              <el-checkbox label="复选框 C"></el-checkbox>
            </el-checkbox-group>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">确定</el-button>
              <el-button type="primary" size="mini" @click="visible = false">重置</el-button>
            </div>
            <i class="el-icon-menu" slot="reference"></i>
          </el-popover> -->
        </template>
        <template slot-scope="{row}">
          <span v-if="row.status === 10"><i class="online" style="background:green"></i>在线</span>
          <span v-if="row.status === 23"><i class="offline" style="background:red"></i>离线</span>
        </template>
      </el-table-column>
      <el-table-column prop="lastConnectTime" label="刷新时间" min-width="120">
        <template slot-scope="{row}">
          {{row.lastConnectTime?$moment(row.lastConnectTime).format('YYYY-MM-DD HH:mm:ss'):'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="readingYesterday" label="昨日用水(t)">
      </el-table-column>
      <el-table-column prop="totalFlow" label="当前净水量(t)">
      </el-table-column>
      <el-table-column prop="phone" label="滤芯使用量" sortable="custom">
        <template slot-scope="{row}">
          <el-progress :stroke-width="20" :percentage="Number(row.totalFlow)>0?Number(row.totalFlow)>Number(row.alertthreshold)?100:Number((row.totalFlow/row.alertthreshold* 100).toFixed(2)):0" :text-inside="true" text-color="#333"></el-progress>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="更换滤芯" min-width="120">
        <template slot-scope="{row}">
          <el-button type="text" @click="filterConfirm(row)">滤芯更换</el-button>
          <el-button type="text" @click="checkRecord(row.deviceId)">更换记录</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="approvalStaleDate" label="批件有效期">
        <template slot-scope="{row}">
          {{row.approvalStaleDate?$moment(row.approvalStaleDate).format('YYYY-MM-DD'):'暂无'}}
        </template>
      </el-table-column>
      <el-table-column prop="detail" label="批件详情">
        <template slot-scope="{row}">
          <el-button type="text" @click="checkIns(row.deviceBaseId)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 联系人弹框 -->
    <el-dialog title="联系人信息" :visible.sync="contactVisible" width="20%" top="40vh" :lock-scroll="false">
      <p class="item">姓名：<span>111</span></p>
      <p class="item">电话：<span>1111</span></p>
    </el-dialog>
    <!-- 滤芯更换记录弹框 -->
    <el-dialog title="滤芯更换记录" :visible.sync="filterVisible" width="40%" :lock-scroll="false">
      <el-dialog width="30%" title="查看图片" class="imgdialog" :lock-scroll="false" :visible.sync="imgVisible" append-to-body>
        <p class="imgbox">
          <img :src="`https://watersupervision.terabits.cn:9091/querymanagement/processquery/changefilterimg/get?access_token=${token}&img=${imgSrc}`" alt="">
        </p>
      </el-dialog>
      <p class="content">更换次数 {{recordList.length}} 次</p>
      <el-table :data="recordList" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" size="small" border>
        <el-table-column prop="deviceId" label="设备编号">
        </el-table-column>
        <el-table-column prop="changeDate" label="更换时间">
          <template slot-scope="{row}">
            {{$moment(row.changeDate).format('YYYY-MM-DD')}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="更换类型">
          <template slot-scope="{row}">
            <span v-if="row.eventtype===1" style="color:red">报警更换</span>
            <span v-if="row.eventtype===3" style="color:green">主动更换</span>
          </template>
        </el-table-column>
        <el-table-column label="查看图片">
          <template slot-scope="{row}">
            <el-button type="text" @click="checkImg(row.imgId)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 滤芯更换弹框 -->
    <el-dialog title="滤芯更换事件处理" :visible.sync="filterDialog" width="20%" :lock-scroll="false">
      <el-form :model="filterForm" :rules="rules" ref="filterForm" :hide-required-asterisk="true">
        <el-form-item label="滤芯更换时间：" prop="date">
          <el-date-picker type="date" placeholder="请选择滤芯更换时间" v-model="filterForm.date" style="width: 100%;" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="滤芯更换图片：" prop="img">
          <base-upload accept="image/jpeg,image/jpeg,image/png" listType="picture" :file.sync="filterForm.img" :isShowTip="false" :fileLimit="1" uploadText="上传滤芯更换图片（只能上传一张图片）" @upload="upload" @remove='remove' />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="filterDialog = false">取 消</el-button>
        <el-button type="primary" @click="sumbitFilter">确定</el-button>
      </div>
    </el-dialog>
    <!-- 批件详情弹框 -->
    <el-dialog title="批件详情" :visible.sync="instructionsVisible" width="30%" :lock-scroll="false">
      <el-descriptions :column="1">
        <el-descriptions-item label="批件检测单位">{{instruction.testorg}}</el-descriptions-item>
        <el-descriptions-item label="批件检测设备型号">{{instruction.testmodel}}</el-descriptions-item>
        <el-descriptions-item label="批件最大额定流量值">{{instruction.approvaltotalflow}}</el-descriptions-item>
        <el-descriptions-item label="批件检测时间">{{instruction.approvaldate}}</el-descriptions-item>
        <el-descriptions-item label="批件过期时间">{{instruction.approvalstaledate}}</el-descriptions-item>
        <el-descriptions-item label="批件记录创建时间">{{instruction.gmtcreate}}</el-descriptions-item>
      </el-descriptions>
      <p class="imgbox" v-if="instruction.approvalId">
        <img :src="`https://watersupervision.terabits.cn:9091/devicemanagement/certificate/report/img/get1?access_token=${token}&reportId=${instruction.approvalId[0]}`" alt="">
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { getdeviceList, getChangeList, getImgId, changeFilter, getInstruction } from '@/api'
import { mapGetters } from 'vuex'
export default {
  props: {
    activeName: {
      type: String
    }
  },
  data () {
    return {
      // 联系人弹窗
      contactVisible: false,
      totalList: [],
      onLineList: [],
      offLineList: [],
      total: 0,
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      argumentsList: {
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: null,
        deviceId: ''
      },
      // 滤芯更换弹框
      filterVisible: false,
      recordList: [],
      imgVisible: false,
      instructionsVisible: false,
      imgSrc: '',
      filterDialog: false,
      imgList: [],
      filterId: {},
      filterForm: {
        img: null,
        date: ''
      },
      rules: {
        img: [
          { required: true, message: '请上传滤芯更换图片', trigger: 'change' }
        ],
        date: [
          { required: true, message: '请选择滤芯更换时间', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      instruction: {},
      // 在离线筛选
      visible: false,
      filterResVal: [],
      filterCondition: [
        { label: '在线', checked: false },
        { label: '离线', checked: false }
      ]
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['cascadedlocation', 'token', 'realname'])
  },
  methods: {
    // 获取设备列表
    async init () {
      const res = await getdeviceList(this.argumentsList)
      this.totalList = res
      this.total = res.length
      this.onLineList = res.filter(el => el.status === 10)
      this.offLineList = res.filter(el => el.status === 23)
      this.getList()
      // console.log('res :>> ', this.totalList, this.total);
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 列表筛选
    paramsChange () {
      this.queryParams.pageNum = 1
      this.init()
    },
    change () {
      console.log('this.checkList :>> ', this.checkList);
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 新增 修改单位
    actionUnit (val) {
      // console.log('val :>> ', val);
      if (val === 'add') {
        this.$router.push('/addunit')
      }
    },
    // 滤芯更换
    filterConfirm (val) {
      // console.log('val :>> ', deviceId);
      this.filterId = val
      this.filterDialog = true
    },
    // 滤芯更换确认
    sumbitFilter () {
      this.$refs.filterForm.validate(async (valid) => {
        if (valid) {
          // console.log('this.filterForm :>> ', this.filterForm, this.imgList);
          const img = await this.checkImgId()
          // console.log('img :>> ', img);
          const params = {
            deviceid: this.filterId.deviceId,
            date: this.$moment(this.filterForm.date).format('YYYY/MM/DD'),
            imgId: img[0],
          }
          await changeFilter(params)

          this.$message.success('滤芯更换成功')
          this.filterDialog = false
          this.init()
          this.$add(
            {
              userName: this.realname,
              module: '设备管理',
              content: `${this.realname}成功添加更换 ${this.filterId.school} 的滤芯记录`,
              type: 3,
              platform: 8,
              operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
            }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取图片id
    checkImgId () {
      const img = Promise.all(this.imgList.map(async (el) => {
        let formdata = new FormData()
        formdata.append('file', el.file, el.file.name)
        // this.updataParams.imgId = await getImgId(this.token, formdata)
        const res = await getImgId(this.token, formdata)
        // console.log('res[0] :>> ', res[0]);
        return res
      }))
      return img
    },
    // 上传图片存
    upload (data) {
      this.imgList.push(data)
    },
    // 删除图片
    remove (data) {
      this.imgList = this.imgList.filter(el => el.file.name !== data.name)
    },
    // 查看滤芯更换记录
    async checkRecord (deviceId) {
      const res = await getChangeList({ deviceid: deviceId })
      // console.log('res :>> ', res);
      this.recordList = res
      this.filterVisible = true
    },
    // 查看图片
    checkImg (val) {
      if (val === null || val === "" || val === undefined) {
        this.$message.error('暂无图片')
      } else {
        this.imgSrc = val
        this.imgVisible = true
      }

    },
    // 查看批件详情
    async checkIns (deviceBaseId) {
      // console.log('id :>> ', id);
      this.instruction = await getInstruction({ deviceBaseId })
      this.instructionsVisible = true
    },
    // 表格排序
    sortChange (column) {
      if (column.order) {
        if (column.order === 'descending') {
          this.queryParams.pageNum = 1
          this.totalList.sort((a, b) => {
            // console.log('object :>> ', a.totalFlow, a.alertthreshold);
            return Number((b.totalFlow / b.alertthreshold * 100).toFixed(2)) - Number((a.totalFlow / a.alertthreshold * 100).toFixed(2))
          })
          // console.log('this.totalList :>> ', this.totalList);
          this.getList()
        } else {
          this.queryParams.pageNum = 1
          this.totalList.sort((a, b) => {
            return Number((a.totalFlow / a.alertthreshold * 100).toFixed(2)) - Number((b.totalFlow / b.alertthreshold * 100).toFixed(2))
          })
          // console.log('this.totalList :>> ', this.totalList);
          this.getList()
        }
      } else {
        this.queryParams.pageNum = 1
        this.init()
      }
      // console.log('column, prop, order :>> ', column, prop, order);
    }
  }
};
</script>

<style scoped lang="scss">
.operation {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 16px;
      }

      border-bottom: 1px solid rgba(157, 158, 160, 0.647);
    }
    .el-dialog__body {
      // padding-top: 0;
      .item {
        margin-top: 10px;
        // font-size: 16px;
        color: #999;
        span {
          font-size: 16px;
          color: #333;
        }
      }
      .imgbox {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
        img {
          // position: absolute;
          width: 240px;
          // height: 100px;
          // transform: scale(0.5);
        }
      }
    }
  }
  .online,
  .offline {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .content {
    margin: 10px 0;
  }
  .el-table {
    .el-table__header {
      .el-icon-menu {
        cursor: pointer;
      }
    }
  }
}
</style>
